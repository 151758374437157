import { useLoanInfo } from "../../hooks";
import { MoneyInput, Input, OptionInput, Button, ConfirmationDialog, Checkbox } from "../";
import { CheckboxInput, Date as DateInput, MoneyValue, Number, Selector } from "../../../infrastructure/inputs";
import { Color, Constants, DateUtil } from "../../../utils";
import { Loan } from "../../../domain/models";
import { LoanStatus, PaymentMethod } from "../../../domain/models/loan";
import { useEffect, useState } from "react";
import { updateLoanPayload } from "../../../domain/repositories/LoansRepository";
import { LoanMapper } from "../../../infrastructure/repositories/rest/mappers";
import { NavLink } from "react-router-dom";
import Routes from "../../../config/Routes";

interface LoanInfoTableProps {
  children: React.ReactNode;
}

const LoanInfoTable: React.FC<LoanInfoTableProps> = ({ children }) => {
  return (
    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
        <div className="overflow-scroll border border-gray-200 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <tbody className="bg-white divide-y divide-gray-200">
              {children}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

interface LoanInfoTableEntryProps {
  title: string;
  children: React.ReactNode;
  dark?: boolean;
}

const LoanInfoTableEntry: React.FC<LoanInfoTableEntryProps> = ({ title, children, dark = false }) => {
  return (
    <tr className={dark ? 'bg-gray-100' : ''}>
      <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">{title}</th>
      <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">
        {children}
      </td>
    </tr>
  )
}

interface LoanInfoProps {
  loan: Loan;
  updateLoan: (id: string, data: updateLoanPayload) => void;
}

const LoanInfo: React.FC<LoanInfoProps> = ({ loan, updateLoan }) => {
  const {
    loadInitialLoanInfo,
    setStatus, status,
    amountApproved, setAmountApproved,
    creditScore, setCreditScore,
    depositedAt, setDepositedAt,
    paymentMethod, setPaymentMethod,
    annualEffectiveInterestRate, setAnnualEffectiveInterestRate,
    estimatedPaymentDate, setEstimatedPaymentDate,
    paidAt, setPaidAt,
    amountPaid, setAmountPaid,
    negotiatedAmountToPay, setNegotiatedAmountToPay,
    negotiatedPaymentDate, setNegotiatedPaymentDate,
    acceptedOnlineSignature, setAcceptedOnlineSignature,
    signedContract, setSignedContract,
    buildPayload,
  } = useLoanInfo();
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    loadInitialLoanInfo(loan);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const canChangeAcceptedOnlineSignature = (
    status.getNumber() === Loan.enumToLoanStatusCd(LoanStatus.open)
  ) || (status.getNumber() === Loan.enumToLoanStatusCd(LoanStatus.approved));

  const canChangeSignedContract = (
    status.getNumber() === Loan.enumToLoanStatusCd(LoanStatus.approved) ||
    status.getNumber() === Loan.enumToLoanStatusCd(LoanStatus.deposited) ||
    status.getNumber() === Loan.enumToLoanStatusCd(LoanStatus.paid)
  ) && !loan.acceptedOnlineSignature;

  return (
    <>
      {
        showDialog ? (
          <ConfirmationDialog
            title="Notificación a cliente"
            content={'¿Deseas enviar un correo de notificación al usuario con los cambios realizados?'}
            denyText="No"
            confirmText="Si"
            cancelText="Cancelar cambios"
            onCancel={() => { setShowDialog(false); }}
            onConfirm={() => { updateLoan(loan.id, { ...buildPayload(), notify_user_via_email: true }); setShowDialog(false); }}
            onDeny={() => { updateLoan(loan.id, { ...buildPayload(), notify_user_via_email: false}); setShowDialog(false); }}
          />
        ) : null
      }
      <form className="flex flex-col space-y-5 bg-white rounded-lg p-10" onSubmit={(event) => { event.preventDefault(); setShowDialog(true); }}>
        <div className="flex flex-col space-y- bg-gray-100 p-4 rounded-lg">
          <div>
            <strong>Solicitante: </strong>
            <NavLink to={Routes.USER_DETAIL_PATH.replace(':id', loan.user.id)} className=" text-sky-600 hover:text-sky-800">
              {loan.user.fullName}
            </NavLink>
          </div>
          <div><strong>Consecutivo: </strong>{loan.consecutive ?? ''}</div>
          <div><strong>Consecutivo de aprobado: </strong>{loan.approvedConsecutive ?? ''}</div>
          <div><strong>Consecutivo de factura: </strong>{loan.invoiceConsecutive ?? ''}</div>
        </div>

        <h2 className="text-xl font-bold">Datos generales del préstamo</h2>
        <LoanInfoTable>
          <LoanInfoTableEntry title="Score crediticio">
            <OptionInput
              className="text-black"
              selectedOption={creditScore.value ?? ''}
              options={Object.keys(Constants.CREDIT_SCORES).map((score) => ({ value: Constants.CREDIT_SCORES[score], label: score }))}
              onChange={(event) => { setCreditScore(Selector.dirtyWithOptions(event.target.value, Object.values(Constants.CREDIT_SCORES))) }}
            />
          </LoanInfoTableEntry>
          <LoanInfoTableEntry title="¿Firma electrónica aceptada?" dark={!canChangeAcceptedOnlineSignature}>
            <Checkbox
              className="p-3"
              disabled={!canChangeAcceptedOnlineSignature}
              value={acceptedOnlineSignature.getBoolean()}
              onChange={(value) => {
                setAcceptedOnlineSignature(CheckboxInput.dirty(String(value)));
              }}
            />
          </LoanInfoTableEntry>
          <LoanInfoTableEntry title="¿Contrato firmado?" dark={!canChangeSignedContract}>
            <Checkbox
              className="p-3"
              disabled={!canChangeSignedContract}
              value={signedContract.getBoolean()}
              onChange={(value) => {
                setSignedContract(CheckboxInput.dirty(String(value)));
              }}
            />
          </LoanInfoTableEntry>
          <LoanInfoTableEntry title="Estado del credito">
            <OptionInput
              className={`text-black ${Color.loanStatusToBgColor(LoanMapper.statusCdToLoanStatus(status.getNumber() ?? 0))}`}
              selectedOption={status.value}
              options={Object.values(LoanStatus).map((status) => ({ value: Loan.enumToLoanStatusCd(status), label: status }))}
              onChange={(event) => { setStatus(Selector.dirtyWithOptions(event.target.value, Loan.loanStatusCds())) }}
            />
          </LoanInfoTableEntry>
          <LoanInfoTableEntry title="Monto aprobado">
            <MoneyInput
              className="p-3"
              value={amountApproved.getMoney() ?? '$'}
              onChange={(event) => {
                setAmountApproved(MoneyValue.dirty(event.target.value));
              }}
            />
          </LoanInfoTableEntry>
          <LoanInfoTableEntry title="Tasa de interés anual efectiva %">
            <Input
              className="p-3"
              value={annualEffectiveInterestRate.value}
              onChange={(event) => { setAnnualEffectiveInterestRate(Number.dirty(event.target.value)) }}
            />
          </LoanInfoTableEntry>
          <LoanInfoTableEntry title="Fecha de aprobación">
            <Input
              className="p-3"
              type="date"
              value={DateInput.dirty(loan.approvedAt ? DateUtil.shortDate(loan.approvedAt) : '').value}
              disabled
            />
          </LoanInfoTableEntry>
          <LoanInfoTableEntry title="Fecha estimada de pago">
            <Input
              className="p-3"
              type="date"
              value={estimatedPaymentDate.value}
              onChange={(event) => { setEstimatedPaymentDate(DateInput.dirty(event.target.value)) }}
              disabled
            />
          </LoanInfoTableEntry>
          <LoanInfoTableEntry title="Fecha de desembolso">
            <Input
              className="p-3"
              type="date"
              value={depositedAt.value}
              onChange={(event) => { setDepositedAt(DateInput.dirty(event.target.value)) }}
              minDate={DateUtil.startOfDay(loan.createdAt)}
            />
          </LoanInfoTableEntry>
        </LoanInfoTable>

        <h2 className="text-xl font-bold">Datos de lo pagado por el usuario</h2>
        <LoanInfoTable>
          <LoanInfoTableEntry title="Fecha real de pago" dark>
            <Input
              type="date"
              className="p-3"
              value={paidAt.value}
              minDate={DateUtil.startOfDay(loan.createdAt)}
              onChange={(event) => { setPaidAt(DateInput.dirty(event.target.value)) }}
              disabled={status.getNumber() !== Loan.enumToLoanStatusCd(LoanStatus.paid)}
            />
          </LoanInfoTableEntry>
          <LoanInfoTableEntry title="Monto real de pago" dark>
            <MoneyInput
              className="p-3"
              value={amountPaid.getMoney() ?? '$'}
              onChange={(event) => {
                setAmountPaid(MoneyValue.dirty(event.target.value));
              }}
              disabled={status.getNumber() !== Loan.enumToLoanStatusCd(LoanStatus.paid)}
            />
          </LoanInfoTableEntry>
          <LoanInfoTableEntry title="Metodo de pago" dark>
            <OptionInput
              className="text-black"
              selectedOption={paymentMethod.value ?? ''}
              options={Object.values(PaymentMethod).map((value) => ({ value: Loan.enumToPaymentMethodCd(value), label: value }))}
              onChange={(event) => { setPaymentMethod(Selector.dirtyWithOptions(event.target.value, Loan.paymentMethodCds())) }}
              disabled={status.getNumber() !== Loan.enumToLoanStatusCd(LoanStatus.paid)}
            />
          </LoanInfoTableEntry>
          <LoanInfoTableEntry title="Días en mora" dark>
            <Input
              type="number"
              className={`p-3 ${loan.daysInArrears !== undefined && loan.daysInArrears !== null && loan.daysInArrears === 0 ? 'bg-green-50' : 'bg-red-50'}`}
              value={loan.daysInArrears !== undefined && loan.daysInArrears !== null ? loan.daysInArrears.toString() : ''}
              disabled
            />
          </LoanInfoTableEntry>
        </LoanInfoTable>

        <h2 className="text-xl font-bold">Acuerdo de pago</h2>
        <LoanInfoTable>
          <LoanInfoTableEntry title="Monto de pago acordado" dark>
            <MoneyInput
              className="p-3"
              value={negotiatedAmountToPay.getMoney() ?? '$'}
              onChange={(event) => {
                setNegotiatedAmountToPay(MoneyValue.dirty(event.target.value));
              }}
              disabled={
                status.getNumber() !== Loan.enumToLoanStatusCd(LoanStatus.deposited) && status.getNumber() !== Loan.enumToLoanStatusCd(LoanStatus.paid)
              }
            />
          </LoanInfoTableEntry>
          <LoanInfoTableEntry title="Fecha de pago acordada" dark>
            <Input
              type="date"
              className="p-3"
              value={negotiatedPaymentDate.value}
              onChange={(event) => { setNegotiatedPaymentDate(DateInput.dirty(event.target.value)) }}
              minDate={DateUtil.startOfDay(loan.createdAt)}
              disabled={
                status.getNumber() !== Loan.enumToLoanStatusCd(LoanStatus.deposited) && status.getNumber() !== Loan.enumToLoanStatusCd(LoanStatus.paid)
              }
            />
          </LoanInfoTableEntry>
        </LoanInfoTable>

        <div className="flex w-full items-center justify-center">
          <Button className="text-md py-2 px-4 w-fit" disabled={false} text="Guardar" type="submit" />
        </div>
      </form>
    </>
  )
}

export default LoanInfo;